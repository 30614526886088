import React from 'react'

const Messaget = props => (
    <div>
        <h1 className='msgh' >TPO's Message</h1>

        <p className='msgp'>The Training and Placement cell of Indian Institute of Information Technology, Lucknow is an integral part of the academics It helps in shaping the careers of our students in the field of Information Technology. </p><br />
        <p className='msgp'>The Training & Placement Cell works diligently to provide a workable environment to the Students that enables them to understand the requirements of corporate & Industrial world. Its aim is to find an appropriate place for students in the corporate. The Cell continuously explore different areas of opportunities in the field of IT for Placement.</p><br /><p className='msgp'> The soft skill & personality development workshops help students to polish their communication skills, build confidence, to make students industry fit professionals. We organize guest lectures, industrial visits & are always striving to strengthen Industrial Interface.
        I express my sincere gratitude to all the organizations that have extended their active co-operation to the T & P Cell in accomplishing its endeavour successfully. I feel privileged to present our students to your esteemed organizations. </p><br />
        <p className='msgp'>I look forward to your cooperation in the placements of our students.</p>
        <br /><p className='cred' >Dr. Abhinesh Kaushik</p>
        <p className='cred' style={{ marginBottom: '2vw' }}>Training and Placement Officer, IIIT Luckow</p>
    </div>
)

export default Messaget